import {createRouter, createWebHistory} from "vue-router"

const routes =  [
    {
        path: "/",
        name: "Frontpage",
        component: () => import("./components/Frontpage")
    }
];

const router = createRouter({
    linkActiveClass: 'active',
    history: createWebHistory(),
    routes: routes
})

export default router;
